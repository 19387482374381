$color-default: #32312f;
$color-light: #fff;
$color-gray: #f9f9f9;

$background-color-default: #32312f;
// $background-color-default: #567537;
$background-color-light: #f5f5f5;
$background-color-gray: #b1afa9;
$header-background-color: #828282;


$font-default: "Poppins", "Zen Kaku Gothic New", sans-serif;
// $font-hiragino-mincho: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝",
//   "MS PMincho", serif;
// $font-hiragino-kakugo: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN",
//   "Hiragino Sans", Meiryo, sans-serif;