@import "../../Foundation/_mediaqueries";
@import "../../Foundation/_variables";

.c-main-title {
  .title-primary {
    font-family: $font-default;
    font-size: 35px;
    font-weight: 300;
    text-align: center;
    letter-spacing: 0.08em;

    @include pc-layout() {
      font-size: 50px;
    }
  }

  .title-secondary {
    font-size: 12px;
    text-align: center;
    display: block;
  }
}
