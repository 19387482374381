/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  word-wrap: break-word;
}

input,
textarea {
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  -webkit-text-size-adjust: 100%;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: bottom;
}

*,
body {
  font-family: "Zen Kaku Gothic New", sans-serif;
}

html {
  font-size: 16px;
  scroll-padding-top: 60px;
}

body {
  color: #32312f;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
li,
dt,
dd,
th,
td,
span,
a {
  line-height: 1.6;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.c-text-center {
  text-align: center;
}

.c-text-left {
  text-align: left;
}

.c-text-right {
  text-align: right;
}

.flex {
  display: flex;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-jc-sb {
  justify-content: space-between;
}

.f-jc-c {
  justify-content: center;
}

.f-jc-fe {
  justify-content: flex-end;
}

.f-ai-c {
  align-items: center;
}

.f-ai-fs {
  align-items: flex-start;
}

.f-ai-fe {
  align-items: flex-end;
}

.f-ac-c {
  align-content: center;
}

.base {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

.l-content-wrap {
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: content-box;
  padding-right: 80px;
  padding-left: 80px;
}
@media only screen and (max-width: 575px) {
  .l-content-wrap {
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 575px) {
  .l-spinner {
    width: 85%;
    margin: 0 auto;
  }
}

.l-content-wrap-default {
  position: relative;
  max-width: 1024px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: content-box;
  padding-right: 2.4%;
  padding-left: 2.4%;
}
@media only screen and (max-width: 575px) {
  .l-content-wrap-default {
    max-width: 100%;
    padding-right: 5%;
    padding-left: 5%;
    box-sizing: border-box;
  }
}

.l-2col-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (min-width: 576px) {
  .u-sp {
    display: none;
  }
}

.u-tab {
  display: none;
}
@media only screen and (min-width: 576px) {
  .u-tab {
    display: block;
  }
}

.u-pc {
  display: none;
}
@media only screen and (min-width: 992px) {
  .u-pc {
    display: block;
  }
}

.l-content-wrap {
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: content-box !important;
  padding-right: 80px !important;
  padding-left: 80px !important;
}
@media only screen and (max-width: 575px) {
  .l-content-wrap {
    max-width: 100%;
    padding-right: 20px !important;
    padding-left: 20px !important;
    box-sizing: border-box !important;
  }
}

@media only screen and (max-width: 575px) {
  .l-spinner {
    width: 85%;
    margin: 0 auto;
  }
}

.l-content-wrap-default {
  position: relative;
  max-width: 1024px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: content-box !important;
  padding-right: 2.4% !important;
  padding-left: 2.4% !important;
}
@media only screen and (max-width: 575px) {
  .l-content-wrap-default {
    max-width: 100%;
    padding-right: 5% !important;
    padding-left: 5% !important;
    box-sizing: border-box !important;
  }
}

.l-2col-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

body.is-drawerActive {
  overflow-y: hidden;
}

body.is-drawerActive .l-header .nav-wrap {
  opacity: 1;
  visibility: visible;
}

.l-header {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #32312f;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
.l-header .inner {
  padding: 4px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (min-width: 992px) {
  .l-header .inner {
    padding: 23px 45px 23px 36px;
  }
}
.l-header .logo-wrap {
  position: relative;
  z-index: 100;
}
.l-header .logo-wrap img {
  width: auto;
  height: 57px;
}
.l-header .humburger-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
@media only screen and (min-width: 992px) {
  .l-header .humburger-wrap {
    align-items: center;
  }
}
.l-header .humburger-wrap .incart-btn {
  text-align: center;
  margin-right: 16px;
}
@media only screen and (min-width: 992px) {
  .l-header .humburger-wrap .incart-btn {
    margin-right: 80px;
  }
}
.l-header .humburger-wrap .incart-btn a {
  width: 32px;
  height: 28px;
  display: block;
  background: url(../img/common/cart_black.png) no-repeat center top/cover;
  position: relative;
  transition: 0.3s;
}
.l-header .humburger-wrap .incart-btn a span {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  color: #fff;
  top: 0px;
  right: -5px;
  background: #ff4343;
  text-align: center;
  line-height: 1;
  font-size: 10px;
  font-weight: bold;
}
.l-header .humburger-wrap .incart-btn p {
  font-size: 12px;
  font-family: "Poppins", "Zen Kaku Gothic New", sans-serif;
  transition: 0.3s;
}
.l-header .humburger-wrap .button-wrap {
  position: relative;
}
.l-header .humburger-wrap .button-wrap::before {
  content: "menu";
  font-family: "Poppins", "Zen Kaku Gothic New", sans-serif;
  font-size: 12px;
  color: #32312f;
  position: absolute;
  top: 80%;
  font-weight: normal;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
}
@media only screen and (min-width: 992px) {
  .l-header .humburger-wrap .button-wrap::before {
    left: -40px;
    top: 48%;
    transform: translate(0, -50%);
  }
}
.l-header .humburger-wrap .button {
  position: relative;
  display: inline-block;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 100;
}
.l-header .humburger-wrap .hamburger {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: 0 0 2rem transparent;
  outline: none;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) {
  .l-header .humburger-wrap .hamburger {
    margin-bottom: -8px;
  }
}
.l-header .humburger-wrap .hamburger-line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 40px;
  height: 2px;
  background-color: transparent;
  transition: inherit;
}
.l-header .humburger-wrap .hamburger-line::before,
.l-header .humburger-wrap .hamburger-line::after {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #32312f;
  content: "";
  transition: inherit;
}
.l-header .humburger-wrap .hamburger-line::before {
  width: 40px;
  top: -10px;
}
.l-header .humburger-wrap .hamburger-line::after {
  width: 27px;
  top: -2px;
  transform: translateX(13px);
}
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line {
  width: 18px;
  background-color: transparent;
}
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line::before,
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line::after {
  width: 28px;
  top: 0;
  right: initial;
  left: initial;
  background-color: #fff;
}
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line::before {
  transform: rotate(35deg);
}
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line::after {
  transform: rotate(-35deg);
}
.l-header .humburger-wrap .visuallyHidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  margin: -1px;
}
.l-header .humburger-wrap .login-sp {
  margin-right: 10px;
  position: relative;
}
.l-header .humburger-wrap .login-sp::before {
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  display: block;
  width: 20px;
  height: 23px;
  background: url(../img/common/icon-login_black.png) no-repeat center top/cover;
  transition: 0.3s;
}
.l-header .humburger-wrap .login-sp input {
  background: none;
  font-family: "Poppins", "Zen Kaku Gothic New", sans-serif;
  font-size: 12px;
  border: none;
  transition: 0.3s;
  color: #000;
  font-weight: 300;
}
.l-header .nav-wrap {
  width: 100%;
  height: 100vh;
  padding: 105px 15px 30px;
  background-color: #828282;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 3;
}
@media only screen and (min-width: 992px) {
  .l-header .nav-wrap::before {
    content: "";
    display: block;
    width: 80vw;
    height: 1px;
    background: #fff;
    bottom: 13%;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
}
@media only screen and (min-width: 992px) {
  .l-header .nav-wrap {
    padding: 165px 135px 100px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 992px) {
  .l-header .nav-wrap .nav {
    width: 65%;
  }
}
.l-header .nav-wrap .nav .list {
  margin-left: 15px;
}
.l-header .nav-wrap .nav .list .item {
  margin-bottom: 15px;
}
@media only screen and (min-width: 992px) {
  .l-header .nav-wrap .nav .list .item {
    margin-bottom: 22px;
  }
}
.l-header .nav-wrap .nav .list .item .link {
  font-family: "Poppins", "Zen Kaku Gothic New", sans-serif;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.08em;
  color: #fff;
  display: inline-block;
}
@media only screen and (min-width: 992px) {
  .l-header .nav-wrap .nav .list .item .link {
    font-size: 28px;
  }
}
.l-header .nav-wrap .nav .list .item.un-item .link {
  padding-left: 65px;
  position: relative;
}
.l-header .nav-wrap .nav .list .item.un-item .link::before {
  content: "";
  width: 50px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #fff;
}
.l-header .nav-wrap .link-wrap {
  margin-bottom: 15px;
  padding-bottom: 30px;
  display: flex;
  align-items: flex-end;
}
@media only screen and (min-width: 992px) {
  .l-header .nav-wrap .link-wrap {
    width: 35%;
    margin-bottom: 100px;
    padding-bottom: 45px;
  }
}
.l-header .nav-wrap .link-wrap .link-contact {
  margin-bottom: 30px;
  margin-left: 15px;
  font-family: "Poppins", "Zen Kaku Gothic New", sans-serif;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.08em;
  color: #fff;
  display: inline-block;
}
@media only screen and (min-width: 992px) {
  .l-header .nav-wrap .link-wrap .link-contact {
    font-size: 28px;
  }
}
.l-header .nav-wrap .link-wrap .c-button-secondary {
  max-width: 345px;
  width: 100%;
  margin: initial;
  padding: 18px 0;
  border: none;
  position: relative;
}
@media only screen and (min-width: 992px) {
  .l-header .nav-wrap .link-wrap .c-button-secondary {
    max-width: 190px;
    margin-top: auto;
    margin-left: auto;
    padding: 11px 0;
  }
}
.l-header .nav-wrap .link-wrap .c-button-secondary::after {
  content: "";
  width: 20px;
  height: 9px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background: center/contain no-repeat url(../img/common/arrow_right_07.png);
  transition: 0.3s;
}
.l-header .nav-wrap .link-wrap .c-button-secondary:hover::after {
  right: 15px;
  background: center/contain no-repeat url(../img/common/arrow_right_08.png);
}
.l-header .nav-wrap .bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media only screen and (min-width: 992px) {
  .l-header .nav-wrap .bottom {
    width: 100%;
    justify-content: flex-start;
  }
}
.l-header .nav-wrap .bottom .sns-wrap {
  padding: 0 15px;
}
@media only screen and (max-width: 575px) {
  .l-header .nav-wrap .bottom .sns-wrap {
    width: 60%;
  }
}
@media only screen and (min-width: 992px) {
  .l-header .nav-wrap .bottom .sns-wrap {
    margin-right: 30px;
  }
}
.l-header .nav-wrap .bottom .sns-wrap a:not(:last-child) {
  margin-right: 20px;
}
.l-header .nav-wrap .bottom .sns-wrap a img {
  width: auto;
  height: 18px;
}
.l-header .nav-wrap .bottom .link-privacy {
  font-family: "Poppins", "Zen Kaku Gothic New", sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.08em;
  color: #fff;
}
.l-header .nav-wrap .login-btn::after {
  background: center/contain no-repeat url(../img/common/arrow_right_07.png);
}
.l-header .nav-wrap .login-btn::before {
  display: none;
}
.l-header .nav-wrap .login-btn input {
  color: #32312f;
  background-color: #fff;
}
.l-header .nav-wrap .cart-btn-nav a {
  margin-right: 80px;
  width: 32px;
  height: 28px;
  display: block;
  background: url(../img/common/cart_white.png) no-repeat center top/cover;
  position: relative;
  transition: 0.3s;
}
.l-header .nav-wrap .cart-btn-nav a span {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  color: #32312f;
  top: 0px;
  right: -5px;
  background: #fff;
  text-align: center;
  line-height: 1;
  font-size: 10px;
  font-weight: bold;
}
.l-header .login-btn {
  width: 135px;
  padding: 0;
  border: none;
  background: none;
  position: relative;
  margin-right: 30px;
}
.l-header .login-btn:hover::after {
  background: center/contain no-repeat url(../img/common/arrow_right_07.png);
}
.l-header .login-btn:hover::before {
  background: center/contain no-repeat url(../img/common/icon-login_black.png);
}
.l-header .login-btn::after {
  content: "";
  width: 20px;
  display: block;
  height: 9px;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  background: center/contain no-repeat url(../img/common/arrow_right_08.png);
  transition: 0.3s;
}
.l-header .login-btn::before {
  content: "";
  width: 9px;
  display: block;
  height: 11px;
  position: absolute;
  top: 50%;
  left: 20px;
  z-index: 2;
  transform: translateY(-50%);
  background: center/contain no-repeat url(../img/common/icon-login_white.png);
  transition: 0.3s;
}
.l-header .login-btn input {
  width: 100%;
  padding: 4px 0 5px;
  color: #fff;
  background-color: #32312f;
  display: block;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.01em;
  border-radius: 27px;
  display: block;
  transition: 0.3s;
  position: relative;
  border: 1px solid #fff;
}
.l-header .login-btn input:hover {
  color: #32312f;
  background-color: #f5f5f5;
  border: 1px solid #32312f;
}
.l-header.is-top {
  background-color: transparent;
  border-bottom: none;
}
.l-header.is-top .button-wrap {
  position: relative;
}
.l-header.is-top .button-wrap::before {
  color: #fff;
}
.l-header.is-top .login-btn:hover::after {
  background: center/contain no-repeat url(../img/common/arrow_right_08.png);
}
.l-header.is-top .login-btn:hover::before {
  background: center/contain no-repeat url(../img/common/icon-login_white.png);
}
.l-header.is-top .login-btn::after {
  background: center/contain no-repeat url(../img/common/arrow_right_07.png);
  transition: 0.3s;
}
.l-header.is-top .login-btn::before {
  background: center/contain no-repeat url(../img/common/icon-login_black.png);
}
.l-header.is-top .login-btn input {
  color: #32312f;
  background-color: #fff;
}
.l-header.is-top .login-btn input:hover {
  color: #fff;
  background-color: #32312f;
}
.l-header.is-top .incart-btn a {
  background: url(../img/common/cart_white.png) no-repeat center top/cover;
}
.l-header.is-top .incart-btn p {
  color: #fff;
}
.l-header.is-top .login-sp::before {
  background: url(../img/common/icon-login_white.png) no-repeat center top/cover;
}
.l-header.is-top .login-sp input {
  color: #fff;
}
.l-header.is-top .hamburger-line::before,
.l-header.is-top .hamburger-line::after {
  background-color: #fff;
}

.l-footer {
  padding: 120px 0 90px;
  background-color: #32312f;
}
@media only screen and (min-width: 992px) {
  .l-footer {
    padding-bottom: 105px;
  }
}
.l-footer .l-content-wrap {
  max-width: 1100px;
}
@media only screen and (max-width: 575px) {
  .l-footer .l-2col-wrap {
    flex-direction: column;
  }
}
.l-footer .logo-wrap {
  margin-bottom: 25px;
}
@media only screen and (min-width: 992px) {
  .l-footer .logo-wrap {
    width: 34%;
  }
}
.l-footer .logo-wrap img {
  width: auto;
  height: 63px;
}
.l-footer .nav-wrap {
  width: 100%;
}
@media only screen and (min-width: 992px) {
  .l-footer .nav-wrap {
    width: 66%;
  }
}
.l-footer .nav-wrap .upper-wrap {
  margin-bottom: 35px;
  padding-bottom: 40px;
  border-bottom: 1px solid #b1afa9;
}
@media only screen and (min-width: 992px) {
  .l-footer .nav-wrap .upper-wrap {
    margin-bottom: 45px;
    padding-bottom: 15px;
    display: flex;
  }
}
.l-footer .nav-wrap .upper-wrap .list {
  margin-bottom: 35px;
  padding-bottom: 30px;
}
@media only screen and (max-width: 575px) {
  .l-footer .nav-wrap .upper-wrap .list {
    border-bottom: 1px solid #b1afa9;
  }
}
@media only screen and (min-width: 992px) {
  .l-footer .nav-wrap .upper-wrap .list {
    width: 45%;
  }
}
.l-footer .nav-wrap .upper-wrap .list .item:not(:last-child) {
  margin-bottom: 20px;
}
.l-footer .nav-wrap .upper-wrap .list .item .link {
  font-family: "Poppins", "Zen Kaku Gothic New", sans-serif;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.08em;
  color: #fff;
}
@media only screen and (min-width: 992px) {
  .l-footer .nav-wrap .upper-wrap .list .item .link {
    font-size: 18px;
  }
}
@media only screen and (min-width: 992px) {
  .l-footer .nav-wrap .upper-wrap .info-wrap {
    width: 55%;
  }
}
.l-footer .nav-wrap .upper-wrap .info-wrap .list-info {
  margin-bottom: 25px;
}
@media only screen and (min-width: 992px) {
  .l-footer .nav-wrap .upper-wrap .info-wrap .list-info {
    margin-bottom: 0px;
  }
}
.l-footer .nav-wrap .upper-wrap .info-wrap .name {
  margin-bottom: 10px;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #fff;
}
.l-footer .nav-wrap .upper-wrap .info-wrap .address {
  margin-bottom: 5px;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 1.8;
  color: #fff;
}
.l-footer .nav-wrap .upper-wrap .info-wrap .tel a {
  font-family: "Poppins", "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.08em;
  color: #fff;
}
.l-footer .nav-wrap .upper-wrap .info-wrap .sns-wrap {
  margin-bottom: 60px;
}
@media only screen and (min-width: 992px) {
  .l-footer .nav-wrap .upper-wrap .info-wrap .sns-wrap {
    margin-bottom: 20px;
  }
}
.l-footer .nav-wrap .upper-wrap .info-wrap .sns-wrap a:not(:first-child) {
  margin-left: 50px;
}
@media only screen and (min-width: 992px) {
  .l-footer .nav-wrap .upper-wrap .info-wrap .sns-wrap a:not(:first-child) {
    margin-left: 20px;
  }
}
.l-footer .nav-wrap .upper-wrap .info-wrap .sns-wrap img {
  width: auto;
  height: 20px;
}
.l-footer .nav-wrap .upper-wrap .info-wrap .link-contact {
  margin-bottom: 55px;
  font-family: "Poppins", "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.12em;
  color: #fff;
  display: inline-block;
  text-decoration: underline;
}
@media only screen and (min-width: 992px) {
  .l-footer .nav-wrap .upper-wrap .info-wrap .link-contact {
    margin: 4px 0 0px;
    font-size: 14px;
  }
}
.l-footer .nav-wrap .upper-wrap .info-wrap .link-reserve {
  max-width: 345px;
  width: 100%;
  position: relative;
}
@media only screen and (min-width: 992px) {
  .l-footer .nav-wrap .upper-wrap .info-wrap .link-reserve {
    width: 190px;
    padding-right: 30px;
  }
}
.l-footer .nav-wrap .upper-wrap .info-wrap .link-reserve::after {
  content: "";
  width: 20px;
  height: 9px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background: center/contain no-repeat url(../img/common/arrow_right_07.png);
  transition: 0.3s;
}
.l-footer .nav-wrap .upper-wrap .info-wrap .link-reserve:hover::after {
  right: 10px;
}
.l-footer .nav-wrap .bottom-wrap {
  display: flex;
  justify-content: space-between;
}
@media only screen and (min-width: 992px) {
  .l-footer .nav-wrap .bottom-wrap {
    margin-right: 9%;
  }
}
.l-footer .nav-wrap .bottom-wrap .link-privacy {
  font-family: "Poppins", "Zen Kaku Gothic New", sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.06em;
  color: #fff;
}
.l-footer .nav-wrap .bottom-wrap .go-to-top {
  padding-right: 15px;
  font-family: "Poppins", "Zen Kaku Gothic New", sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.06em;
  color: #fff;
  position: relative;
}
.l-footer .nav-wrap .bottom-wrap .go-to-top::after {
  content: "";
  width: 6px;
  height: 6px;
  border-top: solid 1px #fff;
  border-right: solid 1px #fff;
  position: absolute;
  top: 10px;
  right: 0;
  transform: rotate(-45deg);
}

.contact-cta {
  padding: 20px 0;
  background: center/cover no-repeat url(../img/company/bg_01.jpg);
}
@media only screen and (min-width: 992px) {
  .contact-cta {
    padding: 100px 0 105px;
  }
}
.contact-cta .contact-inner {
  padding: 20px 5px 35px;
  background-color: #fff;
  position: relative;
}
@media only screen and (min-width: 992px) {
  .contact-cta .contact-inner {
    padding: 80px 5px 100px;
  }
}
.contact-cta .title-contact {
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.12em;
}
@media only screen and (min-width: 992px) {
  .contact-cta .title-contact {
    font-size: 35px;
  }
}
.contact-cta .title-contact-en {
  margin-bottom: 20px;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.12em;
}
@media only screen and (min-width: 992px) {
  .contact-cta .title-contact-en {
    margin-bottom: 20px;
  }
}
.contact-cta .text-contact {
  display: none;
}
@media only screen and (min-width: 992px) {
  .contact-cta .text-contact {
    margin-bottom: 25px;
    font-size: 15px;
    text-align: center;
    letter-spacing: 0.12em;
    display: block;
  }
}
.contact-cta .button-wrap {
  position: relative;
  z-index: 2;
}
@media only screen and (min-width: 992px) {
  .contact-cta .button-wrap {
    display: flex;
    justify-content: center;
  }
}
.contact-cta .button-wrap a {
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
  padding: 13px 0;
  font-size: 15px;
  letter-spacing: 0.1em;
  text-align: center;
  color: #fff;
  background-color: #32312f;
  border-radius: 27px;
  border: 1px solid #32312f;
  display: block;
  transition: 0.3s;
}
@media only screen and (min-width: 992px) {
  .contact-cta .button-wrap a {
    max-width: 320px;
    margin: 0;
  }
}
.contact-cta .button-wrap a.link-tel {
  font-family: "Poppins", "Zen Kaku Gothic New", sans-serif;
  color: #32312f;
  background-color: #fff;
}
.contact-cta .button-wrap a.link-tel:hover {
  color: #fff;
  background-color: #32312f;
}
.contact-cta .button-wrap a:not(:last-child) {
  margin-bottom: 15px;
}
@media only screen and (min-width: 992px) {
  .contact-cta .button-wrap a:not(:last-child) {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 992px) {
  .contact-cta .button-wrap a:not(:first-child) {
    margin-left: 20px;
  }
}
.contact-cta .button-wrap a:hover {
  color: #32312f;
  background-color: #fff;
}

.c-button-primary,
.c-button-secondary {
  width: 190px;
  margin: 0 auto;
  padding: 13px 0;
  font-family: "Poppins", "Zen Kaku Gothic New", sans-serif;
  font-size: 12px;
  text-align: center;
  border: 1px solid #32312f;
  border-radius: 27px;
  display: block;
  transition: 0.3s;
}
@media only screen and (min-width: 992px) {
  .c-button-primary,
  .c-button-secondary {
    width: 32%;
    margin: initial;
    letter-spacing: 0.08em;
  }
}
.c-button-primary:not(:first-child),
.c-button-secondary:not(:first-child) {
  margin-top: 18px;
}
@media only screen and (min-width: 992px) {
  .c-button-primary:not(:first-child),
  .c-button-secondary:not(:first-child) {
    margin-top: 0;
    margin-left: 2%;
  }
}

.c-button-primary {
  color: #fff;
  background-color: #32312f;
}
@media only screen and (min-width: 992px) {
  .c-button-primary {
    letter-spacing: 0.08em;
  }
}
.c-button-primary:hover {
  color: #32312f;
  background-color: #fff;
}

.c-button-secondary {
  color: #32312f;
  background-color: #fff;
}
.c-button-secondary:hover {
  color: #fff;
  background-color: #32312f;
}

.c-main-title .title-primary {
  font-family: "Poppins", "Zen Kaku Gothic New", sans-serif;
  font-size: 35px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 0.08em;
}
@media only screen and (min-width: 992px) {
  .c-main-title .title-primary {
    font-size: 50px;
  }
}
.c-main-title .title-secondary {
  font-size: 12px;
  text-align: center;
  display: block;
}

@media only screen and (min-width: 768px) {
  .u-sp {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .u-pc {
    display: none !important;
  }
}