@import "./_mediaqueries";
// レイアウト

.l-content-wrap {
  // max-width: calc(1366px - 4.8%);
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: content-box !important;
  padding-right: 80px !important;
  padding-left: 80px !important;

  @include sp-layout {
    max-width: 100%;
    padding-right: 20px !important;
    padding-left: 20px !important;
    box-sizing: border-box !important;
  }
}

.l-spinner {
  @include sp-layout {
    width: 85%;
    margin: 0 auto;
  }
}

// レイアウト
.l-content-wrap-default {
  position: relative;

  max-width: 1024px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: content-box !important;
  padding-right: 2.4% !important;
  padding-left: 2.4% !important;

  @include sp-layout {
    max-width: 100%;
    padding-right: 5% !important;
    padding-left: 5% !important;
    box-sizing: border-box !important;
  }
}

.l-2col-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
