@import "./Foundation/mediaqueries";

*,
body {
  // font-family: "Noto Sans JP", sans-serif;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

html {
  font-size: 16px;
  scroll-padding-top: 60px;
}

body {
  color: #32312f;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
li,
dt,
dd,
th,
td,
span,
a {
  line-height: 1.6;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.c-text-center {
  text-align: center;
}

.c-text-left {
  text-align: left;
}

.c-text-right {
  text-align: right;
}

.flex {
  display: flex;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-jc-sb {
  justify-content: space-between;
}

.f-jc-c {
  justify-content: center;
}

.f-jc-fe {
  justify-content: flex-end;
}

.f-ai-c {
  align-items: center;
}

.f-ai-fs {
  align-items: flex-start;
}

.f-ai-fe {
  align-items: flex-end;
}

.f-ac-c {
  align-content: center;
}

.base {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

// レイアウト
.l-content-wrap {
  // max-width: calc(1366px - 4.8%);
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: content-box;
  padding-right: 80px;
  padding-left: 80px;

  @include sp-layout {
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    box-sizing: border-box;
  }
}

.l-spinner {
  @include sp-layout {
    width: 85%;
    margin: 0 auto;
  }
}

// レイアウト
.l-content-wrap-default {
  position: relative;

  max-width: 1024px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: content-box;
  padding-right: 2.4%;
  padding-left: 2.4%;

  @include sp-layout {
    max-width: 100%;
    padding-right: 5%;
    padding-left: 5%;
    box-sizing: border-box;
  }
}

.l-2col-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

// ディスプレイ
.u-sp {
  @include tab-layout() {
    display: none;
  }
}

.u-tab {
  display: none;

  @include tab-layout() {
    display: block;
  }
}

.u-pc {
  display: none;

  @include pc-layout() {
    display: block;
  }
}