@import "../Foundation/_mediaqueries";

.l-footer {
  padding: 120px 0 90px;
  background-color: $background-color-default;

  @include pc-layout() {
    padding-bottom: 105px;
  }

  .l-content-wrap {
    max-width: 1100px;
  }

  .l-2col-wrap {
    @include sp-layout() {
      flex-direction: column;
    }
  }

  .logo-wrap {
    margin-bottom: 25px;

    @include pc-layout() {
      width: 34%;
    }

    img {
      width: auto;
      height: 63px;
    }
  }

  .nav-wrap {
    width: 100%;

    @include pc-layout() {
      width: 66%;
    }

    .upper-wrap {
      margin-bottom: 35px;
      padding-bottom: 40px;
      border-bottom: 1px solid #b1afa9;

      @include pc-layout() {
        margin-bottom: 45px;
        padding-bottom: 15px;
        display: flex;
      }

      .list {
        margin-bottom: 35px;
        padding-bottom: 30px;

        @include sp-layout() {
          border-bottom: 1px solid #b1afa9;
        }

        @include pc-layout() {
          width: 45%;
        }

        .item {
          &:not(:last-child) {
            margin-bottom: 20px;
          }

          .link {
            font-family: $font-default;
            font-size: 20px;
            font-weight: 300;
            letter-spacing: 0.08em;
            color: $color-light;

            @include pc-layout() {
              font-size: 18px;
            }
          }
        }
      }

      .info-wrap {
        @include pc-layout() {
          width: 55%;
        }

        .list-info {
          margin-bottom: 25px;

          @include pc-layout() {
            margin-bottom: 0px;
          }
        }

        .name {
          margin-bottom: 10px;
          font-size: 14px;
          letter-spacing: 0.04em;
          color: $color-light;
        }

        .address {
          margin-bottom: 5px;
          font-size: 14px;
          letter-spacing: 0.04em;
          line-height: 1.8;
          color: $color-light;
        }

        .tel {
          a {
            font-family: $font-default;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.08em;
            color: $color-light;
          }
        }

        .sns-wrap {
          margin-bottom: 60px;

          @include pc-layout() {
            margin-bottom: 20px;
          }

          a {
            &:not(:first-child) {
              margin-left: 50px;

              @include pc-layout() {
                margin-left: 20px;
              }
            }
          }

          img {
            width: auto;
            height: 20px;
          }
        }

        .link-contact {
          margin-bottom: 55px;
          font-family: $font-default;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0.12em;
          color: $color-light;
          display: inline-block;
          text-decoration: underline;

          @include pc-layout() {
            margin: 4px 0 0px;
            font-size: 14px;
          }
        }

        .link-reserve {
          max-width: 345px;
          width: 100%;
          position: relative;

          @include pc-layout() {
            width: 190px;
            padding-right: 30px;
          }

          &::after {
            content: "";
            width: 20px;
            height: 9px;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            background: center / contain no-repeat url(../img/common/arrow_right_07.png);
            transition: 0.3s;
          }

          &:hover {
            &::after {
              right: 10px;
            }
          }
        }
      }
    }

    .bottom-wrap {
      display: flex;
      justify-content: space-between;

      @include pc-layout() {
        margin-right: 9%;
      }

      .link-privacy {
        font-family: $font-default;
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 0.06em;
        color: $color-light;
      }

      .go-to-top {
        padding-right: 15px;
        font-family: $font-default;
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 0.06em;
        color: $color-light;
        position: relative;

        &::after {
          content: "";
          width: 6px;
          height: 6px;
          border-top: solid 1px $color-light;
          border-right: solid 1px $color-light;
          position: absolute;
          top: 10px;
          right: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }
}