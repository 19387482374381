@import "../Foundation/_mediaqueries";

body.is-drawerActive {
  overflow-y: hidden;
}

body.is-drawerActive .l-header .nav-wrap {
  opacity: 1;
  visibility: visible;
}

.l-header {
  width: 100%;
  background-color: $color-light;
  border-bottom: 1px solid $color-default;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;

  .inner {
    padding: 4px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include pc-layout() {
      padding: 23px 45px 23px 36px;
    }
  }

  .logo-wrap {
    position: relative;
    z-index: 100;

    img {
      width: auto;
      height: 57px;
    }
  }

  .humburger-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @include pc-layout() {
      align-items: center;
    }

    .incart-btn {
      text-align: center;
      margin-right: 16px;

      @include pc-layout() {
        margin-right: 80px;
      }

      a {
        width: 32px;
        height: 28px;
        display: block;
        background: url(../img/common/cart_black.png) no-repeat center top/cover;
        position: relative;
        transition: 0.3s;

        span {
          position: absolute;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          display: block;
          color: #fff;
          top: 0px;
          right: -5px;
          background: #ff4343;
          text-align: center;
          line-height: 1;
          font-size: 10px;
          font-weight: bold;
        }
      }

      p {
        font-size: 12px;
        font-family: $font-default;
        transition: 0.3s;
      }
    }

    .button-wrap {
      position: relative;

      &::before {
        content: "menu";
        font-family: $font-default;
        font-size: 12px;
        color: $color-default;
        position: absolute;
        top: 80%;
        font-weight: normal;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s;

        @include pc-layout() {
          left: -40px;
          top: 48%;
          transform: translate(0, -50%);
        }
      }
    }

    .button {
      position: relative;
      display: inline-block;
      text-decoration: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      z-index: 100;
    }

    .hamburger {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      box-shadow: 0 0 2rem transparent;
      outline: none;
      transition: all 0.3s ease-in-out;
      margin-bottom: 0;

      @include pc-layout() {
        margin-bottom: -8px;
      }
    }

    .hamburger-line {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 40px;
      height: 2px;
      background-color: transparent;
      transition: inherit;
    }

    .hamburger-line::before,
    .hamburger-line::after {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      //   background-color: $color-light;
      background-color: $color-default;
      content: "";
      transition: inherit;
    }

    .hamburger-line::before {
      width: 40px;
      top: -10px;
    }

    .hamburger-line::after {
      width: 27px;
      top: -2px;
      transform: translateX(13px);
    }

    .hamburger[aria-expanded="true"] .hamburger-line {
      width: 18px;
      background-color: transparent;
    }

    .hamburger[aria-expanded="true"] .hamburger-line::before,
    .hamburger[aria-expanded="true"] .hamburger-line::after {
      width: 28px;
      top: 0;
      right: initial;
      left: initial;
      background-color: $color-light;
    }

    .hamburger[aria-expanded="true"] .hamburger-line::before {
      transform: rotate(35deg);
    }

    .hamburger[aria-expanded="true"] .hamburger-line::after {
      transform: rotate(-35deg);
    }

    .visuallyHidden {
      position: absolute;
      white-space: nowrap;
      width: 1px;
      height: 1px;
      overflow: hidden;
      border: 0;
      padding: 0;
      clip: rect(0 0 0 0);
      -webkit-clip-path: inset(50%);
      clip-path: inset(50%);
      margin: -1px;
    }

    .login-sp {
      margin-right: 10px;
      position: relative;

      &::before {
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        display: block;
        width: 20px;
        height: 23px;
        background: url(../img/common/icon-login_black.png) no-repeat center top/cover;
        transition: 0.3s;
      }

      input {
        background: none;
        font-family: $font-default;
        font-size: 12px;
        border: none;
        transition: 0.3s;
        color: #000;
        font-weight: 300;
      }
    }
  }

  .nav-wrap {
    width: 100%;
    height: 100vh;
    padding: 105px 15px 30px;
    background-color: $header-background-color;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 3;

    @include pc-layout() {
      &::before {
        content: '';
        display: block;
        width: 80vw;
        height: 1px;
        background: #fff;
        bottom: 13%;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
      }
    }

    @include pc-layout() {
      padding: 165px 135px 100px;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
    }

    .nav {
      @include pc-layout() {
        width: 65%;
      }

      .list {
        margin-left: 15px;

        .item {
          margin-bottom: 15px;

          @include pc-layout() {
            margin-bottom: 22px;
          }

          .link {
            font-family: $font-default;
            font-size: 22px;
            font-weight: 300;
            letter-spacing: 0.08em;
            color: $color-light;
            display: inline-block;

            @include pc-layout() {
              font-size: 28px;
            }
          }

          &.un-item {
            .link {
              padding-left: 65px;
              position: relative;

              &::before {
                content: "";
                width: 50px;
                height: 1px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background-color: $color-light;
              }
            }
          }
        }
      }
    }

    .link-wrap {
      margin-bottom: 15px;
      padding-bottom: 30px;
      display: flex;
      align-items: flex-end;

      @include pc-layout() {
        width: 35%;
        margin-bottom: 100px;
        padding-bottom: 45px;
      }

      .link-contact {
        margin-bottom: 30px;
        margin-left: 15px;
        font-family: $font-default;
        font-size: 22px;
        font-weight: 300;
        letter-spacing: 0.08em;
        color: $color-light;
        display: inline-block;

        @include pc-layout() {
          font-size: 28px;
        }
      }

      .c-button-secondary {
        max-width: 345px;
        width: 100%;
        margin: initial;
        padding: 18px 0;
        border: none;
        position: relative;

        @include pc-layout() {
          max-width: 190px;
          margin-top: auto;
          margin-left: auto;
          padding: 11px 0;
        }

        &::after {
          content: "";
          width: 20px;
          height: 9px;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
          background: center / contain no-repeat url(../img/common/arrow_right_07.png);
          transition: 0.3s;
        }

        &:hover {
          &::after {
            right: 15px;
            background: center / contain no-repeat url(../img/common/arrow_right_08.png);
          }
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @include pc-layout() {
        width: 100%;
        justify-content: flex-start;
      }

      .sns-wrap {
        padding: 0 15px;

        @include sp-layout() {
          width: 60%;
        }

        @include pc-layout() {
          margin-right: 30px;
        }

        a {
          &:not(:last-child) {
            margin-right: 20px;
          }

          img {
            width: auto;
            height: 18px;
          }
        }
      }

      .link-privacy {
        font-family: $font-default;
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 0.08em;
        color: $color-light;
      }
    }

    .login-btn {
      &::after {
        background: center/contain no-repeat url(../img/common/arrow_right_07.png);
      }

      &::before {
        display: none;
      }

      input {
        color: $color-default;
        background-color: $color-light;
      }
    }

    .cart-btn-nav {
      a {
        margin-right: 80px;
        width: 32px;
        height: 28px;
        display: block;
        background: url(../img/common/cart_white.png) no-repeat center top/cover;
        position: relative;
        transition: 0.3s;

        span {
          position: absolute;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          display: block;
          color: $color-default;
          top: 0px;
          right: -5px;
          background: $color-light;
          text-align: center;
          line-height: 1;
          font-size: 10px;
          font-weight: bold;
        }
      }
    }
  }

  .login-btn {
    width: 135px;
    padding: 0;
    border: none;
    background: none;
    position: relative;
    margin-right: 30px;

    @include sp-layout() {
      // display: none;
    }

    &:hover {
      &::after {
        background: center/contain no-repeat url(../img/common/arrow_right_07.png);
      }

      &::before {
        background: center/contain no-repeat url(../img/common/icon-login_black.png);
      }
    }

    &::after {
      content: "";
      width: 20px;
      display: block;
      height: 9px;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      background: center/contain no-repeat url(../img/common/arrow_right_08.png);
      transition: 0.3s;
    }

    &::before {
      content: "";
      width: 9px;
      display: block;
      height: 11px;
      position: absolute;
      top: 50%;
      left: 20px;
      z-index: 2;
      transform: translateY(-50%);
      background: center/contain no-repeat url(../img/common/icon-login_white.png);
      transition: 0.3s;
    }

    input {
      width: 100%;
      padding: 4px 0 5px;
      color: $color-light;
      background-color: $color-default;
      display: block;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.01em;
      border-radius: 27px;
      display: block;
      transition: 0.3s;
      position: relative;
      border: 1px solid $color-light;

      &:hover {
        color: $color-default;
        background-color: $background-color-light;
        border: 1px solid $color-default;
      }
    }

  }

  &.is-top {
    background-color: transparent;
    border-bottom: none;

    .button-wrap {
      position: relative;

      &::before {
        color: $color-light;
      }
    }

    .login-btn {

      &:hover {
        &::after {
          background: center/contain no-repeat url(../img/common/arrow_right_08.png);
        }

        &::before {
          background: center/contain no-repeat url(../img/common/icon-login_white.png);
        }
      }

      &::after {
        background: center/contain no-repeat url(../img/common/arrow_right_07.png);
        transition: 0.3s;
      }

      &::before {
        background: center/contain no-repeat url(../img/common/icon-login_black.png);
      }

      input {
        color: $color-default;
        background-color: $color-light;

        &:hover {
          color: $color-light;
          background-color: $background-color-default;
        }
      }
    }

    .incart-btn {
      a {
        background: url(../img/common/cart_white.png) no-repeat center top/cover;
      }

      p {
        color: $color-light;
      }
    }

    .login-sp {
      &::before {
        background: url(../img/common/icon-login_white.png) no-repeat center top/cover;
      }

      input {
        color: $color-light;
      }
    }

    .hamburger-line::before,
    .hamburger-line::after {
      background-color: $color-light;
    }
  }
}