@import "./Foundation/_mediaqueries";

.c-button-primary,
.c-button-secondary {
  width: 190px;
  margin: 0 auto;
  padding: 13px 0;
  font-family: $font-default;
  font-size: 12px;
  text-align: center;
  border: 1px solid $background-color-default;
  border-radius: 27px;
  display: block;
  transition: 0.3s;

  @include pc-layout() {
    width: 32%;
    margin: initial;
    letter-spacing: 0.08em;
  }

  &:not(:first-child) {
    margin-top: 18px;

    @include pc-layout() {
      margin-top: 0;
      margin-left: 2%;
    }
  }
}

// 共通ボタン
.c-button-primary {
  color: $color-light;
  background-color: $background-color-default;

  @include pc-layout() {
    letter-spacing: 0.08em;
  }

  &:hover {
    color: $color-default;
    background-color: $color-light;
  }
}

.c-button-secondary {
  color: $color-default;
  background-color: $color-light;

  @include pc-layout() {
  }

  &:hover {
    color: $color-light;
    background-color: $background-color-default;
  }
}
