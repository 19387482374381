@import "./Foundation/_mediaqueries";

.contact-cta {
  padding: 20px 0;
  background: center / cover no-repeat url(../img/company/bg_01.jpg);

  @include pc-layout() {
    padding: 100px 0 105px;
  }

  .contact-inner {
    padding: 20px 5px 35px;
    background-color: $color-light;
    position: relative;

    @include pc-layout() {
      padding: 80px 5px 100px;
    }
  }

  .title-contact {
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.12em;

    @include pc-layout() {
      font-size: 35px;
    }
  }

  .title-contact-en {
    margin-bottom: 20px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.12em;

    @include pc-layout() {
      margin-bottom: 20px;
    }
  }

  .text-contact {
    display: none;

    @include pc-layout() {
      margin-bottom: 25px;
      font-size: 15px;
      text-align: center;
      letter-spacing: 0.12em;
      display: block;
    }
  }

  .button-wrap {
    position: relative;
    z-index: 2;

    @include pc-layout() {
      display: flex;
      justify-content: center;
    }

    a {
      max-width: 280px;
      width: 100%;
      margin: 0 auto;
      padding: 13px 0;
      font-size: 15px;
      letter-spacing: 0.1em;
      text-align: center;
      color: $color-light;
      background-color: $background-color-default;
      border-radius: 27px;
      border: 1px solid $background-color-default;
      display: block;
      transition: 0.3s;

      @include pc-layout() {
        max-width: 320px;
        margin: 0;
      }

      &.link-tel {
        font-family: $font-default;
        color: $color-default;
        background-color: $color-light;

        &:hover {
          color: $color-light;
          background-color: $background-color-default;
        }
      }

      &:not(:last-child) {
        margin-bottom: 15px;

        @include pc-layout() {
          margin-bottom: 0;
        }
      }

      &:not(:first-child) {
        @include pc-layout() {
          margin-left: 20px;
        }
      }

      &:hover {
        // opacity: 0.6;
        color: $color-default;
        background-color: $color-light;
      }
    }
  }
}
