@media only screen and (min-width: 768px) {
  .u-sp {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .u-pc {
    display: none !important;
  }
}
